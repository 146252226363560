/* 그룹 소속 접근 권한 확인*/
export function memberAccessAuth(memberList: any) {
  let isMember = false;
  const authStore = useAuthStore();
  if (memberList && memberList.length > 0) {
    memberList.map((user: any) => {
      if (user.user_no === authStore.user_id) isMember = true;
    });
  }

  //유저가 관리자일 경우 , 마스터로 취급
  if (checkUserCapability(authStore.capability.level, 9, 'up')) {
    isMember = true
  }

  return isMember
}
