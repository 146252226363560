/* 권한별 이용권 명칭 */
export const getLicense = (user_type: any, capability: any) => {
  //capability->value의 첫글자 대문자 변환
  const licenseName = capability.value.charAt(0).toUpperCase() + capability.value.slice(1)

  const license = (licenseName: any) => {
    switch (licenseName) {
      case 'Author_star':
        return `Premium Star`
      case 'Author_pro':
        return `Premium Pro`
      case 'Author_ai':
        return `AI School`
      case 'Author':
        return `Premium`
      case 'Starter':
        return `Free`
      default:
        return licenseName
    }
  }

  switch (user_type) {
    case 'teampl100':
      return `${license(licenseName)} 멤버십(팀플백)`
    case 'suoe':
      return `${license(licenseName)} 멤버십(서울시교육청)`
    case 'icoe':
      return `${license(licenseName)} 멤버십(인천광역시교육청)`
    case 'vcda':
      return `${license(licenseName)} 멤버십(바우처수요기관)`
    case 'ssss':
      return `${license(licenseName)} 멤버십(삼성스마트스쿨)`
    case 'dgfe':
      return `${license(licenseName)} 멤버십(대구미래교육원)`
    case 'normal':
      return `${license(licenseName)} 멤버십`
    default:
      return `${license(licenseName)} 멤버십`
  }
}
/* CAPABILITY level,value 명칭 */
export const CAPABILITY = (type: any, capability: any) => {
  switch (type) {
    case 'level':
      switch (capability) {
        case 10:
          return '매니저'
        case 9:
          return '관리자'
        case 8:
          return '총괄 디렉터'
        case 7:
          return '디렉터'
        case 6:
          return '스타 작가'
        case 5:
          return '프로 작가'
        case 4:
          return '작가'
        case 3:
          return '비기너'
        case 2:
        default:
          return '스타터'
      }
    case 'value':
      switch (capability) {
        case 'administrator':
          return '팀플백계정'
        case 'author':
          return '작가'
        case 'starter':
        default:
          return '스타터'
      }
  }
}

/** 유저 권한 확인 함수
 * @param level capability 레벨
 * @param limit_level level에 제한을 둘 기준
 * @param scale - limit_level을 기준으로 권한 범위 설정
 * - default: 같은 권한의 레벨만 True
 * - up:기준 이상의 레벨 True
 * - down:기준 이하의 True
 * @returns {boolean} True: 유효한 권한, False: 유효하지 않은 권한
 */
 export const checkUserCapability = (level: number, limit_level: number, scale: string) => {
  switch (scale) {
    case 'up':
      return level >= limit_level
    case 'down':
      return level <= limit_level
    default:
      return level == limit_level
  }
}