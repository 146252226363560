interface IGroup {
  group_title: string
  group_status: string
  create_user_no: string
  initStatus: boolean
  isMember: boolean
  isOwner: boolean
}

export const useGroupStore = defineStore('group', {
  state: () => {
    return {
      group: {
        group_title: '',
        group_status: '',
        create_user_no: '',
        initStatus: false,
        isMember: false,
        isOwner: false,
      } as IGroup,
      activity_no: null as number | null, // 현재 활동 번호. by jhkim. 2024.10.29
      subject_no: null as number | null, // 현재 글감 번호. by jhkim. 2024.10.29
    }
  },
  getters: {
    group_title: (state): string => state.group.group_title,
    group_status: (state): string => state.group.group_status,
    create_user_no: (state): any => state.group.create_user_no,
    initStatus: (state): boolean => state.group.initStatus,
    isMember: (state): boolean => state.group.isMember,
    isOwner: (state): boolean => state.group.isOwner,
    // isRelatedUser 추가함: by jhkim. 2024.09.30
    // 이 그룹의 오너, 맴버, 관리자(admin)인지 확인
    isRelatedUser: (state): boolean => state.group.isMember || state.group.isOwner || useAuthStore().isAdmin,
  },
  actions: {
    setGroup(data: IGroup, memberList: any) {
      this.group = data;
      this.group.initStatus = true;
      this.group.isMember = memberAccessAuth(memberList);
      this.group.isOwner = data.create_user_no === useAuthStore().user_id;
      this.activity_no = null;
      this.subject_no = null;
    },
    resetGroup() {
      this.group = {
        group_title: '',
        group_status: '',
        create_user_no: '',
        initStatus: false,
        isMember: false,
        isOwner: false,
      };
      this.activity_no = null;
      this.subject_no = null;
    },
    setActivityNo(activity_no: number) {
      this.activity_no = activity_no;
    },
    setSubjectNo(subject_no: number) {
      this.subject_no = subject_no;
    },
  },
})

if (import.meta.hot) {  //HMR
  import.meta.hot.accept(acceptHMRUpdate(useGroupStore, import.meta.hot))
}
